import React from "react"
import Layout from "components/layout/layout-child-site"
import SEO from "components/seo"
import { ValuePropositions } from "components/sections"
import { Button, Typography, Container, Section, Faqs, Row,Testimonial, Link  } from "components/ui"
import Image from "components/image"

const Page = () => (
  <Layout>
		<SEO refKey="page"/>
    <Section>
      <Container>
        <Row>
          <div className="col-md-12 text-center">
              <Typography tag="h1" className="text-center">
                <strong>MongoDB</strong> backup, the easy way.
              </Typography>
              <Typography tag="h3">Database backup solution on auto-pilot.</Typography>

            <div className="mt-16">
              <Button color="green" size="large" href="https://my.simplebackups.com/register?sb_source=website&sb_term=mongodb-backup">
                Configure your MongoDB backup<i className="fa fa-arrow-right"></i>
              </Button>
            </div>
            <div className="mt-16">
              <ValuePropositions></ValuePropositions>
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    <Section color="alt">
      <Container>
        <Row>
          <div className="col-md-6">
          <Typography tag="h2">
              Hastle free setup
            </Typography>
            <Typography tag="p" className="mt-2">
              <p>Set up your backup in 3 simple steps.</p>
              1. 	Pick your storage<br/>
              2. 	Connect your server<br/>
              3. 	Schedule your backup<br/>
            </Typography>
            <div className="flex items-center mb-3">
                <div style={{maxHeight:50, maxWidth: 40, width: '100%'}} className="mr-3" ><Image filename="digitalocean.png" alt="MongoDB backup on DigitalOcean"/></div>
                <div style={{maxHeight:50, maxWidth: 50, width: '100%'}} className="mr-3"><Image filename="aws.png" alt="MongoDB backup on AWS"/></div>
                <div style={{maxHeight:50, maxWidth: 50, width: '100%'}} className="mr-3"><Image filename="dropbox.png" alt="MongoDB backup on Dropbox"/></div>
                <div style={{maxHeight:50, maxWidth: 35, width: '100%'}} className="mr-3"><Image filename="gdrive.png" alt="MongoDB backup on Google Cloud"/></div>
                <div style={{maxHeight:50, maxWidth: 50, width: '100%'}} className="mr-3"><Image filename="wasabi.png" alt="MongoDB backup on Wasabi"/></div>
                <div style={{maxHeight:50, maxWidth: 50, width: '100%'}} className="mr-3"><Image filename="backblaze.png" alt="MongoDB backup on Backblaze"/></div>
                <div style={{maxHeight:50, maxWidth: 50, width: '100%'}} className=""><Image filename="filebase.png" alt="MongoDB backup on Filebase"/></div>
            </div>
            <Link href="https://simplebackups.com/features/" arrow="right">See all features</Link>
          </div>
          <div className="col-md-6">
            <Typography tag="h2">
              Simple Plans
            </Typography>
            <Typography tag="p" className="mt-2">
              <p>Start for free, preserve & protect your backups today!</p>
              <Button color="pruple" size="medium" href="https://simplebackups.com/pricing/">
                View plans<i className="fa fa-arrow-right"></i>
              </Button>
            </Typography>
          </div>
        </Row>
      </Container>
    </Section>
    <Section color="primary">
      <Container>
        <Row>
          <div className="col-md-12">
            <div className="flex justify-center items-center">
              <div className="mr-12">
                <Testimonial
                  avatar='testimonials-ap.png'
                  name="Aditya"
                  role="gumletapp"
                  content="@SimpleBackupsIO is among the small companies I love. They are simply awesome."
                />
              </div>
              <div className="mr-12">
                <Testimonial
                  avatar="testimonials-wb.png"
                  name="Will"
                  role="Sidekick Digital"
                  content="The simplest products are almost always the best, and this epitomises that. I highly recommend the team working on SimpleBackups."
                />
              </div>
              <div>
                <Testimonial
                  avatar="testimonials-default.png"
                  name="Len"
                  role="Two-and-two"
                  content="The tool works really well btw, tested a few others but they all looked like they where designed by the sysadmin.."
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <div className="col-md-12">
            <Typography tag="h2" className="text-center mb-6">
                Frequently Asked Questions
            </Typography>
            <Faqs ids={['how_does_it_work', 'who_holds']} />
          </div>
        </Row>
      </Container>
    </Section>
  </Layout>)

export default Page

export const frontmatter = {
  pageMetaData: {
    refKey: "page",
    title: "MongoDB backup solution",
    description: "MongoDB backup synced to your preferred storage, the easy way. Scheduling, notifications, integration with all cloud providers.",
    author: "simplebackups.com"
  }
}